import { envConfig } from '../env.config';

export const links = {
  HELP: 'help',
  HUB_PRODUCTS: 'products',
  HUB: 'user'
};

export const urls = {
  HELP: `${envConfig.HELP}`,
  HUB_PRODUCTS: `${envConfig.HUB_PRODUCTS}`,
  HUB: `${envConfig.HUB}`,
  PRODUCT_PAGE: `${envConfig.PRODUCT_PAGE}/language`,
  LOGIN: `${envConfig.PLATFORM}/login?redirect=returnURL`,
  SIGNUP: `${envConfig.APP?.URL}/signup?redirect=returnURL`,
  LOGOUT: `${envConfig.PLATFORM}/logout?redirect=returnURL`
};

export const fireProduct = {
  LINK: 'https://hotmart.com/pt-br/marketplace/produtos/fire-festival-2023',
  PRODUCT_ID: 788921,
  TAG: 'banner-fire'
};

export const productQueryString = {
  PRODUCT_MARKETPLACE: [
    {
      key: 'sck',
      value: 'HOTMART_SITE'
    }
  ],
  PRODUCT_SEASON: [
    {
      key: 'src',
      value: 'HOTMART_BLACK_NOVEMBER'
    }
  ]
};

export const featureTags = {
  PRODUCT_PAGE: '32',
  MARKETPLACE: '33',
  ORGANIC: '34'
};

export const sourceParams = {
  CREATOR_MODAL: 'creator-modal',
  CREATOR_PAGE: 'creator-page'
};

export const eventsBlackNovember = {
  PRODUCT_DISCOUNT: 'is_product_with_bn_discount',
  CREATOR_BN: 'is_bn_producer'
};

export const productCard = {
  TITLE_MAX_LENGTH: 63
};

export const FILTER = {
  CATEGORIES: 'categories',
  ORDERING: {
    MOST_RECENT: 'most_recent',
    BEST_SELLER: 'best_seller',
    MOST_RELEVANT: 'most_relevant',
    BEST_RATED: 'best_rated',
    MOST_RATED: 'most_rated'
  },
  PAYMENT: {
    ACCOUNT: 'HOTMART_ACCOUNT_BALANCE'
  },
  FORMATS: 'formats',
  SEPARATOR: '_-_'
};

export const seasonInfo = {
  NAME: 'Black November',
  PREFIX: 'black-november',
  TYPE: 'STANDARD',
  SLUG: 'black-november',
  COUPON_TYPE: 'black_november_2023',
  RESULTS_PAGE: 'black-november',
  COUPONS_VALUES: ['0.10', '0.20', '0.30', '0.40', '0.50'],
  LOGO: 'static/images/black-november/logo-hotmart-black-november.png',
  LOGO_SEARCH: 'static/images/black-november/logo-black-november.png',
  BANNER: '/s/static/images/black-november/banner-black-november.svg',
  BANNER_MOBILE: '/s/static/images/black-november/logo-mobile-black-november.png',
  LOGO_SEARCH_MOBILE: 'static/images/black-november/logo.svg',
  LOGO_SEARCH_SECONDARY: 'static/images/black-november/black-november-search.png',
  BANNER_TEXT_PT: 'no <strong>Hotmart</strong> Marketplace',
  BANNER_TEXT_ES: 'en <strong>Hotmart</strong> Marketplace',
  STYLE: 'static/styles/black-november.css',
  GROUP_KEY: 'BLACK_NOVEMBER_2023',
  TEXT_SEARCH_PT: 'Agora você pode <strong>desenvolver uma habilidade nova, com conteúdos digitais</strong> muito bem avaliados, com mais economia.',
  TEXT_SEARCH_ES: 'Ahora puedes aprender algo nuevo con contenidos digitales muy bien evaluados y <strong>ahorrando más.</strong>',
  TEXT_SEARCH_PT_MOBILE: 'Agora você pode aprender uma <strong>habilidade nova</strong> com mais economia.',
  TEXT_SEARCH_ES_MOBILE: 'Paga menos y aprende más.',
  PRODUCT_CARD_IMAGE: '/s/static/images/black-november/black-november-strip-banner.svg',
  PRODUCT_CARD_LOGO: '/s/static/images/black-november/black-november-strip-logo.svg',
  PRODUCT_CARD_LOGO_MOBILE: '/s/static/images/black-november/black-november-strip-logo_mobile.svg',
  START_DATE:
    process.env.ENV_CONFIG !== 'production'
      ? '2023/09/15 22:00:00 GMT-0300'
      : '2023/11/01 00:00:00 GMT-0300',
  END_DATE: '2023/12/09 23:59:59 GMT-0300',
  sections: [
    {
      'screen': 'MAIN_PAGE',
      'name': 'BANNER_MAIN_PAGE',
      'components': [
        {
          'deviceType': 'WEB',
          'name': 'TEXT',
          'type': 'TEXT',
          'attributes': {
            'value': 'Aqui tem o que você precisa para descobrir como economizar dinheiro, organizar suas contas e até investir.'
          }
        },
        {
          'deviceType': 'WEB',
          'name': 'LOGO_IMAGE',
          'type': 'IMAGE',
          'attributes': {
            'fileName': 'logo-black-november.png',
            'objectKey': '4427a063-3e90-4891-864f-b41759056ffe',
            'url': 'static/images/black-november/logo-black-november.png',
            'urlFinal': 'static/images/black-november/logo-black-november.png'
          }
        },
        {
          'deviceType': 'MOBILE',
          'name': 'BACKGROUND_IMAGE',
          'type': 'IMAGE',
          'attributes': {
            'fileName': 'banner-black-november-mobile.png',
            'objectKey': '0a047132-1abc-4941-855c-f4700935fa93',
            'url': 'static/images/black-november/banner-black-november-mobile.png',
            'urlFinal': 'static/images/black-november/banner-black-november-mobile.png'
          }
        },
        {
          'deviceType': 'MOBILE',
          'name': 'TEXT',
          'type': 'TEXT',
          'attributes': {
            'value': 'Aqui tem conteúdos sobre como economizar, gerir e até investir seu dinheiro.'
          }
        },
        {
          'deviceType': 'MOBILE',
          'name': 'SEARCH_TEXT',
          'type': 'TEXT',
          'attributes': {
            'value': 'Digite sua busca'
          }
        },
        {
          'deviceType': 'WEB',
          'name': 'SEARCH_TEXT',
          'type': 'TEXT',
          'attributes': {
            'value': 'Digite sua busca'
          }
        },
        {
          'deviceType': 'MOBILE',
          'name': 'LOGO_IMAGE',
          'type': 'IMAGE',
          'attributes': {
            'fileName': 'logo-mobile-black-november.png',
            'objectKey': 'a2695d15-6fdd-4225-9c2e-5d6f82cf784a',
            'url': 'static/images/black-november/logo-mobile-black-november.png',
            'urlFinal': 'static/images/black-november/logo-mobile-black-november.png'
          }
        },
        {
          'deviceType': 'WEB',
          'name': 'BACKGROUND_IMAGE',
          'type': 'IMAGE',
          'attributes': {
            'fileName': 'banner-black-november.svg',
            'objectKey': 'd9aa4878-f881-4e08-bdbb-b7a84af5d5dc',
            'url': 'static/images/black-november/banner-black-november.svg',
            'urlFinal': 'static/images/black-november/banner-black-november.svg'
          }
        }
      ],
      'enabled': true
    },
    {
      'screen': 'HOME',
      'name': 'BANNER_MIDDLE',
      'components': [
        {
          'deviceType': 'WEB',
          'name': 'BACKGROUND_IMAGE',
          'type': 'IMAGE',
          'attributes': {
            'fileName': '1246x288px_Campanha-Suas-Finanças_banner_topo_Pagina Inicial.png',
            'objectKey': '9bc3487c-291a-4e28-bbb2-275cef0c1d19',
            'url': 'https://dsp-cdn.hotmart.com/campaign/media/l55zp/9bc3487c-291a-4e28-bbb2-275cef0c1d19/1246x288px_Campanha-Suas-Finanças_banner_topo_Pagina Inicial.png',
            'urlFinal': 'https://static-media.hotmart.com/qXlw_hyp-psd9g98zoWt0rX4ATA=/1246x288/smart/filters:format(webp)/dsp-campaign.hotmart.com/campaign/media/l55zp/9bc3487c-291a-4e28-bbb2-275cef0c1d19/1246x288px_Campanha-Suas-Finan%C3%A7as_banner_topo_Pagina%20Inicial.png'
          }
        },
        {
          'deviceType': 'WEB',
          'name': 'LOGO_IMAGE',
          'type': 'IMAGE',
          'attributes': {
            'fileName': '200x35px_Campanha-Suas-Finanças_banner_topo_logo_Pagina Inicial.png',
            'objectKey': '0e9864dc-d379-42e1-b6a8-483c2e7078a4',
            'url': 'https://dsp-cdn.hotmart.com/campaign/media/l55zp/0e9864dc-d379-42e1-b6a8-483c2e7078a4/200x35px_Campanha-Suas-Finanças_banner_topo_logo_Pagina Inicial.png',
            'urlFinal': 'https://static-media.hotmart.com/aFve7IJEg0rOp1wBkaBK_DPGJG4=/200x35/smart/filters:format(webp)/dsp-campaign.hotmart.com/campaign/media/l55zp/0e9864dc-d379-42e1-b6a8-483c2e7078a4/200x35px_Campanha-Suas-Finan%C3%A7as_banner_topo_logo_Pagina%20Inicial.png'
          }
        },
        {
          'deviceType': 'WEB',
          'name': 'TEXT',
          'type': 'TEXT',
          'attributes': {
            'value': 'Quer lidar melhor com seu dinheiro? Aprenda com conteúdos digitais a economizar, organizar contas, investir e mais.'
          }
        },
        {
          'deviceType': 'WEB',
          'name': 'CTA',
          'type': 'TEXT',
          'attributes': {
            'value': 'Confira os conteúdos',
            'ctaBtnColor': '#523df6'
          }
        },
        {
          'deviceType': 'MOBILE',
          'name': 'BACKGROUND_IMAGE',
          'type': 'IMAGE',
          'attributes': {
            'fileName': '374x214px_Campanha-Suas-Finanças_banner_topo_mobile_Pagina Inicial.png',
            'objectKey': '3b42acb4-a575-4af7-97f5-14aa6b88fdf5',
            'url': 'static/images/black-november/banner-black-november-mobile.png',
            'urlFinal': 'static/images/black-november/banner-black-november-mobile.png'
          }
        },
        {
          'deviceType': 'MOBILE',
          'name': 'CTA',
          'type': 'TEXT',
          'attributes': {
            'value': 'Confira os conteúdos',
            'ctaBtnColor': '#523df6'
          }
        },
        {
          'deviceType': 'MOBILE',
          'name': 'TEXT',
          'type': 'TEXT',
          'attributes': {
            'value': 'Quer lidar melhor com seu dinheiro? Aprenda com conteúdos digitais a economizar, organizar contas, investir e mais.'
          }
        },
        {
          'deviceType': 'MOBILE',
          'name': 'LOGO_IMAGE',
          'type': 'IMAGE',
          'attributes': {
            'fileName': '180x25px_Campanha-Suas-Finanças_banner_topo_logo_Pagina Inicial.png',
            'objectKey': 'ba5b4a93-04b9-4727-ab37-1d4646f95182',
            'url': 'https://dsp-cdn.hotmart.com/campaign/media/l55zp/ba5b4a93-04b9-4727-ab37-1d4646f95182/180x25px_Campanha-Suas-Finanças_banner_topo_logo_Pagina Inicial.png',
            'urlFinal': 'https://static-media.hotmart.com/HhWg3xd4_8cPO7akgzFbHKWmUKE=/180x25/smart/filters:format(webp)/dsp-campaign.hotmart.com/campaign/media/l55zp/ba5b4a93-04b9-4727-ab37-1d4646f95182/180x25px_Campanha-Suas-Finan%C3%A7as_banner_topo_logo_Pagina%20Inicial.png'
          }
        }
      ],
      'enabled': true
    }
  ]
};

export const sectionCampaign = {
  BACKGROUND_IMAGE: 'BACKGROUND_IMAGE',
  BACKGROUND_IMAGE_MOBILE: 'BACKGROUND_IMAGE_MOBILE',
  BANNER_MIDDLE: 'BANNER_MIDDLE',
  BANNER_TOP: 'BANNER_TOP',
  CATEGORY_PAGE: 'CATEGORY_PAGE',
  COMBO: 'COMBO',
  CTA: 'CTA',
  CTA_MOBILE: 'CTA_MOBILE',
  CTA_URL: 'CTA_URL',
  CTA_URL_MOBILE: 'CTA_URL_MOBILE',
  HOME: 'HOME',
  LOGO_IMAGE: 'LOGO_IMAGE',
  LOGO_IMAGE_MOBILE: 'LOGO_IMAGE_MOBILE',
  MAIN_IMAGE: 'MAIN_IMAGE',
  MAIN_IMAGE_MOBILE: 'MAIN_IMAGE_MOBILE',
  MAIN_PAGE: 'MAIN_PAGE',
  MENU: 'MENU',
  PRODUCT_CARD: 'PRODUCT_CARD',
  RESULT_PAGE: 'SEARCH_RESULT_PAGE',
  TEXT: 'TEXT',
  TEXT_MOBILE: 'TEXT_MOBILE',
  SEARCH_TEXT: 'SEARCH_TEXT',
  SEARCH_TEXT_MOBILE: 'SEARCH_TEXT_MOBILE'
};

export const statusCampaign = {
  RUNNING: 'RUNNING',
  PAUSE: 'PAUSE'
};

export const typesCampaign = {
  FILTER: 'FILTER',
  GROUPS: 'GROUPS',
  STANDARD: 'STANDARD'
};

export const bannerCarousel = {
  MIN: 4
};

export type CampaignType = Partial<typeof campaign[keyof typeof campaign]>;

export const campaign = {
  'dia-das-mulheres': {
    NAME: 'Dia das Mulheres',
    PREFIX: 'diadasmulheres',
    SLUG: 'dia-das-mulheres',
    LANGUAGE: 'pt',
    COUPON_TYPE: '',
    GROUP_KEY: ['women_day'],
    RESULTS_PAGE: `${envConfig.APP.URL}/c/dia-das-mulheres`,
    LOGO: 'static/images/black-november/logo-hotmart-branco.svg',
    START_DATE: '2021/02/16 22:00:00 GMT-0300',
    END_DATE: '2021/03/10 23:59:59 GMT-0300',
    STYLE: 'static/styles/woman-day.css'
  },
  'cifra-club': {
    NAME: 'Cifra Club',
    PREFIX: 'cifra-club',
    SLUG: 'cifra-club',
    LANGUAGE: 'pt',
    COUPON_TYPE: '',
    GROUP_KEY: ['cifra_club'],
    RESULTS_PAGE: `${envConfig.APP.URL}/c/cifra-club`,
    LOGO: '',
    LOGO_SEARCH: 'static/images/cifra-club/logo-cifra-club.svg',
    LOGO_SEARCH_MOBILE: 'static/images/cifra-club/logo-cifra-club.svg',
    LOGO_SEARCH_SECONDARY: 'static/images/cifra-club/logo-hotmart.svg',
    START_DATE: '2021/04/22 22:00:00 GMT-0300',
    END_DATE: '2021/12/24 23:59:59 GMT-0300',
    STYLE: 'static/styles/cifra-club.css',
    BANNER: 'static/images/cifra-club/banner-cifraclub.svg',
    TEXT_SEARCH: '<strong>No Hotmart Marketplace, você aprende ou desenvolve seu talento musical.</strong>',
    BANNER_RESULTS_PAGE_LINK: `${envConfig.APP.URL}/c/cifra-club`,
    BANNER_RESULTS_PAGE_SUBTITLE_PT: 'No Hotmart Marketplace, você aprende ou desenvolve seu talento musical',
    BANNER_RESULTS_PAGE_COLOR: '#A18CE3',
    BANNER_RESULTS_PAGE_IMAGE: 'static/images/cifra-club/banner-cifraclub.svg',
    BANNER_RESULTS_PAGE_LOGO: 'static/images/cifra-club/cifra-club-results.png',
    BANNER_HOME_BUTTON_PT: 'Confira os conteúdos',
    productQueryString: {
      PRODUCT_MARKETPLACE: [
        {
          key: 'sck',
          value: 'HOTMART_SITE'
        }
      ],
      PRODUCT_SEASON: [
        {
          key: 'src',
          value: 'HOTMART_CIFRACLUB'
        }
      ]
    }
  },
  'orgulho-nerd': {
    NAME: 'Orgulho Nerd',
    PREFIX: 'orgulho-nerd',
    SLUG: 'orgulho-nerd',
    LANGUAGE: 'pt',
    COUPON_TYPE: '',
    GROUP_KEY: ['orgulho_nerd'],
    RESULTS_PAGE: `${envConfig.APP.URL}/c/orgulho-nerd`,
    LOGO: '',
    LOGO_SEARCH: 'static/images/orgulho-nerd/logo-orgulho-nerd.png',
    LOGO_SEARCH_SECONDARY: '',
    START_DATE: '2021/05/24 22:00:00 GMT-0300',
    END_DATE: '2021/06/14 23:59:59 GMT-0300',
    STYLE: 'static/styles/orgulho-nerd.css',
    BANNER: 'static/images/orgulho-nerd/banner-orgulho-nerd.svg',
    TEXT_SEARCH:
      'Uma <strong>seleção de conteúdos digitais</strong> para você aprender mais sobre o universo geek.',
    BANNER_RESULTS_PAGE_LINK:
      'https://www.hotmart.com/c/orgulho-nerd?itm_medium=internal&itm_source=mktplace&itm_campaign=pt-br_sales_marketplace_banner_pagina-de-busca-orgulho-nerd',
    BANNER_RESULTS_PAGE_TITLE_PT: 'Orgulho Nerd no Hotmart Marketplace',
    BANNER_RESULTS_PAGE_SUBTITLE_PT:
      'Tem uma seleção de conteúdos digitais do universo geek para você: sobre games, criação, história, programação e mais!',
    BANNER_RESULTS_PAGE_COLOR: '#F04E23',
    BANNER_RESULTS_PAGE_IMAGE:
      'static/images/orgulho-nerd/nerd-pride-banner.svg',
    BANNER_RESULTS_PAGE_LOGO:
      'static/images/orgulho-nerd/banner-logo-results.svg',
    BANNER_RESULTS_PAGE_MAIN:
      'static/images/orgulho-nerd/logo-orgulho-nerd-results.png',
    BANNER_HOME_BUTTON_PT: 'Confira os conteúdos',
    productQueryString: {
      PRODUCT_MARKETPLACE: [
        {
          key: 'sck',
          value: 'HOTMART_SITE'
        }
      ],
      PRODUCT_SEASON: [
        {
          key: 'src',
          value: 'HOTMART_ORGULHONERD'
        }
      ]
    }
  },
  'um-por-cento': {
    NAME: 'Um por cento',
    PREFIX: 'um-por-cento',
    SLUG: 'um-por-cento',
    LANGUAGE: 'pt',
    COUPON_TYPE: '',
    GROUP_KEY: ['donation'],
    RESULTS_PAGE: `${envConfig.APP.URL}/c/um-por-cento`,
    RESULTS_PAGE_ITM: `${envConfig.APP.URL}/c/um-por-cento?itm_medium=internal&itm_source=mktplace&itm_campaign=pt-br_sales_marketplace_banner_home-um-por-cento`,
    LOGO: '',
    LOGO_SEARCH: 'static/images/um-por-cento/um-por-cento_logo_resultado.png',
    LOGO_SEARCH_MOBILE: 'static/images/um-por-cento/um-por-cento_logo_resultado_mobile.png',
    LOGO_IMAGE: 'static/images/um-por-cento/um-por-cento_logo_home.png',
    LOGO_IMAGE_MOBILE:
      'static/images/um-por-cento/um-por-cento_logo_home_mobile.png',
    LOGO_SEARCH_SECONDARY: '',
    START_DATE: '2021/08/01 22:00:00 GMT-0300',
    END_DATE: '2031/12/31 23:59:59 GMT-0300',
    STYLE: 'static/styles/um-por-cento.css',
    BANNER: 'static/images/um-por-cento/um-por-cento_banner_resultado.png',
    BANNER_MOBILE: 'static/images/um-por-cento/um-por-cento_banner_resultado_mobile.png',
    // BANNER_HOME: 'static/images/um-por-cento/um-por-cento_banner.png',
    // BANNER_HOME_MOBILE: 'static/images/um-por-cento/um-por-cento_banner_mobile.png',
    BANNER_HOME: 'static/images/um-por-cento/um-por-cento_background_plain.svg',
    BACKGROUND_LOGO: 'static/images/um-por-cento/um-por-cento_background_logo.svg',
    BACKGROUND_LOGO_MOBILE: 'static/images/um-por-cento/um-por-cento_background_logo_mobile.svg',
    BANNER_HOME_BUTTON_PT: 'Quero conhecer',
    BANNER_CENTRAL_DESCRIPTION_PT: 'Ao escolher um produto cadastrado no <span style="color: #FF3366"}>Hotmart UmPorCento</span>, uma porcentagem da compra é destinada a uma instituição social.',
    BANNER_CENTRAL_DESCRIPTION_PT_MOBILE: 'Conheça produtos que revertem parte do valor de suas vendas para o Hotmart UmPorCento.',
    TEXT_SEARCH: 'Ao escolher um produto cadastrado no <span style="color: #FF3366"}>Hotmart UmPorCento</span>, uma porcentagem da compra é destinada a uma instituição social.',
    TEXT_SEARCH_MOBILE: 'Ao escolher um produto cadastrado no Hotmart UmPorCento, uma porcentagem da compra é destinada a uma instituição social.',
    TEXT_SEARCH_PT: 'Ao escolher um produto cadastrado no <span style="color: #FF3366"}>Hotmart UmPorCento</span>, uma porcentagem da compra é destinada a uma instituição social.',
    TEXT_SEARCH_EN: 'Ao escolher um produto cadastrado no <span style="color: #FF3366"}>Hotmart UmPorCento</span>, uma porcentagem da compra é destinada a uma instituição social.',
    TEXT_SEARCH_ES: 'Ao escolher um produto cadastrado no <span style="color: #FF3366"}>Hotmart UmPorCento</span>, uma porcentagem da compra é destinada a uma instituição social.',
    MINILOGO: 'static/images/um-por-cento/um-por-cento_logo_banner.png',
    MINILOGO_MOBILE: 'static/images/um-por-cento/um-por-cento_logo_banner_mobile.png',
    PRODUCT_CARD_MAIN: 'static/images/um-por-cento/um-por-cento_produto_main.png',
    PRODUCT_CARD_IMAGE: 'static/images/um-por-cento/um-por-cento_produto_background.png',
    PRODUCT_CARD_TEXT: 'Contribui para o Hotmart 1%',
    productQueryString: {
      PRODUCT_MARKETPLACE: [
        {
          key: 'sck',
          value: 'HOTMART_SITE'
        }
      ],
      PRODUCT_SEASON: [
        {
          key: 'src',
          value: 'HOTMART_UMPORCENTO'
        }
      ]
    }
  },
  'cyber-week': {
    NAME: 'CyberWeek',
    PREFIX: 'cyberweek',
    SLUG: 'cyber-week',
    COUPON_TYPE: 'BLACK_NOVEMBER_2022',
    RESULTS_PAGE: `${envConfig.APP.URL}/black-november`,
    LOGO: 'static/images/black-november/logo-hotmart-branco.svg',
    LOGO_SEARCH: 'static/images/cyber-week/cyber-week-logo.png',
    BANNER: '/s/static/images/cyber-week/cyber-week-ribbon.png',
    BANNER_MOBILE: '/s/static/images/black-november/banner-ribbon-mobile.png',
    LOGO_SEARCH_MOBILE: 'static/images/cyber-week/cyber-week-logo.png',
    BANNER_TEXT_PT: false,
    BANNER_TEXT_ES: false,
    STYLE: 'static/styles/black-november.css',
    TEXT_SEARCH_PT: '<strong>A temporada de ofertas se estendeu!</strong><br/>É a última chance para você desenvolver uma habilidade nova com mais economia.',
    TEXT_SEARCH_ES: '<strong>Ampliamos la temporada de ofertas.</strong><br/>Pero esta es tu última oportunidad de aprender más pagando menos.',
    TEXT_SEARCH_PT_MOBILE: 'Última chance para você desenvolver uma habilidade nova <strong>com mais economia.</strong>',
    TEXT_SEARCH_ES_MOBILE: 'Última oportunidad de aprender más <strong>pagando menos.</strong>',
    PRODUCT_CARD_IMAGE: '/s/static/images/black-november/black-november-strip-banner.svg',
    PRODUCT_CARD_LOGO: '/s/static/images/black-november/black-november-strip-logo.svg',
    PRODUCT_CARD_LOGO_MOBILE: '/s/static/images/black-november/black-november-strip-logo_mobile.svg',
    START_DATE:
      process.env.ENV_CONFIG !== 'production'
        ? '2021/11/27 12:00:00 GMT-0300'
        : '2021/11/27 12:00:00 GMT-0300',
    END_DATE: '2021/12/05 23:59:59 GMT-0300'
  }
};

export const categoryCard = {
  INTERNET: 'internet',
  DESIGN: 'design',
  TECHNOLOGY: 'information_technology',
  PERSONAL_DEVELOPMENT: 'personal_development',
  GASTRONOMY: 'gastronomy'
};

export const categoriesRoutes = (t) => ({
  'education': { value: t('urls.education') },
  'personal_development': { value: t('urls.personal_development') },
  'business_and_careers': { value: t('urls.business_and_careers') },
  'health_and_sports': { value: t('urls.health_and_sports') },
  'finance': { value: t('urls.finance') }
});

export const categories = [
  { slug: 'animals_and_plants', useCategoryPage: true },
  { slug: 'software', useCategoryPage: true },
  { slug: 'building_and_constructing', useCategoryPage: true },
  { slug: 'gastronomy', useCategoryPage: true },
  { slug: 'personal_development', useCategoryPage: true },
  { slug: 'design', useCategoryPage: true },
  { slug: 'law', useCategoryPage: true },
  { slug: 'environment', useCategoryPage: true },
  { slug: 'education', useCategoryPage: true },
  // { slug: "entertainment" },
  { slug: 'spirituality', useCategoryPage: true },
  { slug: 'finance', useCategoryPage: true },
  { slug: 'general', useCategoryPage: true },
  { slug: 'literature', useCategoryPage: true },
  // { slug: "hobbies" },
  { slug: 'idioms', useCategoryPage: true },
  { slug: 'internet', useCategoryPage: true },
  { slug: 'fashion_and_beauty', useCategoryPage: true },
  { slug: 'music_and_arts', useCategoryPage: true },
  { slug: 'business_and_careers', useCategoryPage: true },
  // { slug: "relationships" },
  { slug: 'health_and_sports', useCategoryPage: true },
  // { slug: "sexuality" },
  { slug: 'information_technology', useCategoryPage: true }
];

export const BANNER_TYPE = {
  EXTERNAL: 'EXTERNAL',
  INTERNAL: 'INTERNAL'
};

export const RESULTS_VIEW_TYPE = {
  LIST: 'list',
  GRID: 'grid'
};

export const isMostAccessedBrazil = [
  'Marketing Digital',
  'Empreendedorismo',
  'Maquiagem',
  'Investimentos',
  'Saúde e Esportes',
  'Culinária e Gastronomia',
  'Idiomas',
  'Inglês',
  'Concursos',
  'Estética',
  'Meditar',
  'Fotografia',
  'Desenho',
  'Artesanato'
];

export const MOST_ACCESSED = (t) => [
  {
    key: 'digital_marketing',
    value: t('search.terms.digital_marketing')
  },
  {
    key: 'entrepreneurship',
    value: t('search.terms.entrepreneurship')
  },
  {
    key: 'makeup',
    value: t('search.terms.makeup')
  },
  {
    key: 'investiments',
    value: t('search.terms.investiments')
  },
  {
    key: 'health_and_sports',
    value: t('category.health_and_sports.name'),
    term: t('category.health_and_sports.name').replace(' & ', ' and ')
  },
  {
    key: 'gastronomy',
    value: t('category.gastronomy.name'),
    term: t('category.gastronomy.name').replace(' & ', ' and ')
  },
  {
    key: 'languages',
    value: t('search.terms.languages')
  },
  {
    key: 'english',
    value: t('search.terms.english')
  },
  {
    key: 'contest',
    value: t('search.terms.contest')
  },
  {
    key: 'aesthetics',
    value: t('search.terms.aesthetics')
  },
  {
    key: 'meditate',
    value: t('search.terms.meditate')
  },
  {
    key: 'photography',
    value: t('search.terms.photography')
  },
  {
    key: 'design',
    value: t('search.terms.design')
  },
  {
    key: 'craftsmanship',
    value: t('search.terms.craftsmanship')
  }
];

export const FAVORITE_TAGS = (t) => [
  {
    key: 'digital_marketing',
    value: t('search.terms.digital_marketing'),
    selected: false
  },
  {
    key: 'entrepreneurship',
    value: t('search.terms.entrepreneurship'),
    selected: false
  },
  {
    key: 'investiments',
    value: t('search.terms.investiments'),
    selected: false
  },
  {
    key: 'health_and_sports',
    value: t('category.health_and_sports.name'),
    term: t('category.health_and_sports.name').replace(' & ', ' and '),
    selected: false
  },
  {
    key: 'english',
    value: t('search.terms.english'),
    selected: false
  },
  {
    key: 'gastronomy',
    value: t('search.terms.gastronomy'),
    selected: false
  },
  {
    key: 'languages',
    value: t('search.terms.languages'),
    selected: false
  },
  {
    key: 'personal development',
    value: t('search.terms.personal_development'),
    selected: false
  },
  {
    key: 'makeup',
    value: t('search.terms.makeup'),
    selected: false
  },
  {
    key: 'education',
    value: t('search.terms.education'),
    selected: false
  },
  {
    key: 'aesthetics',
    value: t('search.terms.aesthetics'),
    selected: false
  },
  {
    key: 'photography',
    value: t('search.terms.photography'),
    selected: false
  },
  {
    key: 'business',
    value: t('search.terms.business'),
    selected: false
  },
  {
    key: 'finance',
    value: t('search.terms.finance'),
    selected: false
  },
  {
    key: 'slimming',
    value: t('search.terms.slimming'),
    selected: false
  }
];

export const launchProduct = {
  '4147054': {
    PPL_START_DATE: '2021/10/29 14:00:00 GMT-0300',
    PL_START_DATE: '2022/03/29 08:00:00 GMT-0300',
    L_START_DATE: '2022/04/07 09:00:00 GMT-0300',
    L_END_DATE: '2022/04/07 23:59:59 GMT-0300',
    PPL_CONTENT: '',
    PL_CONTENT: `Quer saber se é pra você?
    Pois se liga…
    Se tu quer ter sucesso na internet como:
    Influencer e aumentar suas publis,
    Blogueiro/a e crescer seus seguidores,
    Empresário e divulgar mais sua empresa ou produtos,
    Dono do seu próprio negócio e vender pra mais clientes,
    Nenhuma das opções acima, mas quer bombar suas redes sociais;
    Esse curso é pra tu, sim!!

    “Mas, e eu que sou chato?”

    Ah papai, esse curso é pra você também!!

    Tu vai descobrir que todo mundo pode ser interessante.

    E mais ainda, vai entender que tu não precisa ser interessante o tempo todo, mas só por alguns minutos do seu dia.

    No curso Como Ser Interessante Na Internet tu vai aprender como ser dono do ativo mais importante da atualidade; a atenção das pessoas.`,
    L_CONTENT: `Quer saber se é pra você?
    Pois se liga…
    Se tu quer ter sucesso na internet como:
    Influencer e aumentar suas publis,
    Blogueiro/a e crescer seus seguidores,
    Empresário e divulgar mais sua empresa ou produtos,
    Dono do seu próprio negócio e vender pra mais clientes,
    Nenhuma das opções acima, mas quer bombar suas redes sociais;
    Esse curso é pra tu, sim!!

    “Mas, e eu que sou chato?”

    Ah papai, esse curso é pra você também!!

    Tu vai descobrir que todo mundo pode ser interessante.

    E mais ainda, vai entender que tu não precisa ser interessante o tempo todo, mas só por alguns minutos do seu dia.

    No curso Como Ser Interessante Na Internet tu vai aprender como ser dono do ativo mais importante da atualidade; a atenção das pessoas.`,
    PPL2_CONTENT: '',
    PPL_VIDEO: '',
    PL_VIDEO: '',
    L_VIDEO: '',
    PPL2_VIDEO: '',
    PPL_TITLE: 'Quero participar!',
    PL_TITLE: 'Inscreva-se aqui',
    L_TITLE: 'Garanta antes que acabe:',
    PPL2_TITLE: 'Em breve, nova turma!',
    PPL_SUBTITLE: 'Cadastre-se agora:',
    PL_SUBTITLE: 'Início das vendas em breve!',
    L_SUBTITLE: '',
    PPL2_SUBTITLE: 'Cadastre-se para ser informado!',
    L_ADVAMTAGE: `Whindersson Nunes, professor.
    Que história é essa?
    Ele começou do nada, sem dinheiro, sem equipamentos, sem acesso ou contatos. Mas com muito estudo, dedicação e estratégia se tornou um fenômeno multiplataforma.

    Viralizar uma vez na internet pode até ser sorte…

    Mas pra ter 3.985.162.443 visualizações no youtube, se tornar um dos 50 maiores influenciadores do mundo com

    57,1 milhões de seguidores no instagram,
    43,5 milhões no youtube e
    20,3 milhões no tik tok …
    (e nem contamos as outras redes sociais)
    Eu te garanto que é preciso técnica e estratégia.
    E é exatamente isso que você vai aprender no curso Como Ser Interessante na Internet!`,
    L_DURATION: '',
    L_NUMBER_LESSON: '',
    L_CERTIFICATE: false,
    TOKEN_KLICKSEND: 'OLT97l'
  },
  '1713163': {
    PPL_START_DATE: '2021/10/29 14:00:00 GMT-0300',
    PL_START_DATE: '2021/11/11 00:00:00 GMT-0300',
    L_START_DATE: '2021/11/22 00:00:00 GMT-0300',
    L_END_DATE: '2021/11/28 23:59:59 GMT-0300',
    PPL_CONTENT: '',
    PL_CONTENT: `Black Week dos Ingressos VIP para o FIRE 2022
    De 1 a 3 de setembro. No Expominas, Belo Horizonte.

    Apenas aqui nesta página, 10%OFF no ingresso VIP de 22/11 até 28/11
    Aproveite Antes Que os Cupons da Black Week Acabem!


    FIRE FESTIVAL 2022

    Um festival de 3 dias para você se conectar com mentes brilhantes do Marketing Digital e do Empreendedorismo.

    Um convite para experimentar a Inovação, fazer Networking e construir novos Negócios.


    INGRESSO VIP

    3 dias de evento: a experiência completa

    Acesso presencial a todas as áreas do evento, inclusive à área VIP para networking com palestrantes e outros convidados VIP

    Conteúdo completo ao VIVO, com acesso às gravações das palestras (pós evento)

    Tradução simultânea das palestras e certificado de participação

    1 KIT completo de boas-vindas no primeiro dia de evento!

    10%OFF na Black Week até 28/11, às 23:59!

    De R$ 2197 por apenas 12x de R$ 166 sem juros ou R$ 1997

    Aproveite essa condição exclusiva, pois os próximos lotes estarão com preço maior!`,
    L_CONTENT: `Black Week dos Ingressos VIP para o FIRE 2022
    De 1 a 3 de setembro. No Expominas, Belo Horizonte.

    Apenas aqui nesta página, 10%OFF no ingresso VIP de 22/11 até 28/11
    Aproveite Antes Que os Cupons da Black Week Acabem!


    FIRE FESTIVAL 2022

    Um festival de 3 dias para você se conectar com mentes brilhantes do Marketing Digital e do Empreendedorismo.

    Um convite para experimentar a Inovação, fazer Networking e construir novos Negócios.


    INGRESSO VIP

    3 dias de evento: a experiência completa

    Acesso presencial a todas as áreas do evento, inclusive à área VIP para networking com palestrantes e outros convidados VIP

    Conteúdo completo ao VIVO, com acesso às gravações das palestras (pós evento)

    Tradução simultânea das palestras e certificado de participação

    1 KIT completo de boas-vindas no primeiro dia de evento!

    10%OFF na Black Week até 28/11, às 23:59!

    De R$ 2197 por apenas 12x de R$ 166 sem juros ou R$ 1997

    Aproveite essa condição exclusiva, pois os próximos lotes estarão com preço maior!`,
    PPL2_CONTENT: `Vem aí: FIRE 2022 | De 1 a 3 de setembro. No Expominas, Belo Horizonte.

    Fique por dentro das novidades se inscrevendo em nossa lista de espera.
    Você será avisado em primeira mão sobre promoções no Hotmart Marketplace e novas datas de venda!



    FIRE FESTIVAL 2022

    Um festival de 3 dias para você se conectar com mentes brilhantes do Marketing Digital e do Empreendedorismo.

    Um convite para experimentar a Inovação, fazer Networking e construir novos Negócios.


    INGRESSO VIP

    3 dias de evento: a experiência completa

    Acesso presencial a todas as áreas do evento, inclusive à área VIP para networking com palestrantes e outros convidados VIP

    Conteúdo completo ao VIVO, com acesso às gravações das palestras (pós evento)

    Tradução simultânea das palestras e certificado de participação

    1 KIT completo de boas-vindas no primeiro dia de evento!

    10%OFF na Black Week até 28/11, às 23:59!

    De R$ 2197 por apenas 12x de R$ 166 sem juros ou R$ 1997

    Aproveite essa condição exclusiva, pois os próximos lotes estarão com preço maior!`,
    PPL_VIDEO: '',
    PL_VIDEO: 'https://youtu.be/ziu96rYBfVE',
    L_VIDEO: 'https://youtu.be/ziu96rYBfVE',
    PPL2_VIDEO: 'https://youtu.be/ziu96rYBfVE',
    PPL_TITLE: 'Quero participar!',
    PL_TITLE: 'Inscreva-se aqui',
    L_TITLE: 'Garanta antes que acabe:',
    PPL2_TITLE: 'Em breve, nova turma!',
    PPL_SUBTITLE: 'Cadastre-se agora:',
    PL_SUBTITLE: 'Início das vendas em breve!',
    L_SUBTITLE: '',
    PPL2_SUBTITLE: 'Cadastre-se para ser informado!',
    L_ADVAMTAGE: '',
    L_DURATION: '',
    L_NUMBER_LESSON: '',
    L_CERTIFICATE: false,
    TOKEN_KLICKSEND: '3QTvaL'
  },
  '1919674': {
    PPL_START_DATE: '2021/10/29 14:00:00 GMT-0300',
    PL_START_DATE: '2022/03/29 08:00:00 GMT-0300',
    L_START_DATE: '2022/04/07 09:00:00 GMT-0300',
    L_END_DATE: '2022/04/07 23:59:59 GMT-0300',
    PPL_CONTENT: '',
    PL_CONTENT: `Quer saber se é pra você?
    Pois se liga…
    Se tu quer ter sucesso na internet como:
    Influencer e aumentar suas publis,
    Blogueiro/a e crescer seus seguidores,
    Empresário e divulgar mais sua empresa ou produtos,
    Dono do seu próprio negócio e vender pra mais clientes,
    Nenhuma das opções acima, mas quer bombar suas redes sociais;
    Esse curso é pra tu, sim!!

    “Mas, e eu que sou chato?”

    Ah papai, esse curso é pra você também!!

    Tu vai descobrir que todo mundo pode ser interessante.

    E mais ainda, vai entender que tu não precisa ser interessante o tempo todo, mas só por alguns minutos do seu dia.

    No curso Como Ser Interessante Na Internet tu vai aprender como ser dono do ativo mais importante da atualidade; a atenção das pessoas.`,
    L_CONTENT: `Quer saber se é pra você?
    Pois se liga…
    Se tu quer ter sucesso na internet como:
    Influencer e aumentar suas publis,
    Blogueiro/a e crescer seus seguidores,
    Empresário e divulgar mais sua empresa ou produtos,
    Dono do seu próprio negócio e vender pra mais clientes,
    Nenhuma das opções acima, mas quer bombar suas redes sociais;
    Esse curso é pra tu, sim!!

    “Mas, e eu que sou chato?”

    Ah papai, esse curso é pra você também!!

    Tu vai descobrir que todo mundo pode ser interessante.

    E mais ainda, vai entender que tu não precisa ser interessante o tempo todo, mas só por alguns minutos do seu dia.

    No curso Como Ser Interessante Na Internet tu vai aprender como ser dono do ativo mais importante da atualidade; a atenção das pessoas.`,
    PPL2_CONTENT: '',
    PPL_VIDEO: '',
    PL_VIDEO: '',
    L_VIDEO: '',
    PPL2_VIDEO: '',
    PPL_TITLE: 'Quero participar!',
    PL_TITLE: 'Inscreva-se aqui',
    L_TITLE: 'Garanta antes que acabe:',
    PPL2_TITLE: 'Em breve, nova turma!',
    PPL_SUBTITLE: 'Cadastre-se agora:',
    PL_SUBTITLE: 'Início das vendas em breve!',
    L_SUBTITLE: '',
    PPL2_SUBTITLE: 'Cadastre-se para ser informado!',
    L_ADVAMTAGE: `Whindersson Nunes, professor.
    Que história é essa?
    Ele começou do nada, sem dinheiro, sem equipamentos, sem acesso ou contatos. Mas com muito estudo, dedicação e estratégia se tornou um fenômeno multiplataforma.

    Viralizar uma vez na internet pode até ser sorte…

    Mas pra ter 3.985.162.443 visualizações no youtube, se tornar um dos 50 maiores influenciadores do mundo com

    57,1 milhões de seguidores no instagram,
    43,5 milhões no youtube e
    20,3 milhões no tik tok …
    (e nem contamos as outras redes sociais)
    Eu te garanto que é preciso técnica e estratégia.
    E é exatamente isso que você vai aprender no curso Como Ser Interessante na Internet!`,
    L_DURATION: '',
    L_NUMBER_LESSON: '',
    L_CERTIFICATE: false,
    TOKEN_KLICKSEND: 'OLT97l'
  }
};

export const campaignPageRoute = {
  'pt-br': 'campanhas',
  'en': 'campaigns',
  'de': 'campaigns',
  'it': 'campaigns',
  'ja': 'campaigns',
  'fr': 'campagnes',
  'es': 'campanas',
  'ar': 'campaigns'
};

export const productPageRoute = {
  'pt-br': 'produtos',
  'en': 'products',
  'de': 'products',
  'it': 'products',
  'ja': 'products',
  'fr': 'des-produits',
  'es': 'productos',
  'ar': 'products'
};

export const categoryRoute = {
  'pt-br': 'categorias',
  'en': 'categories',
  'it': 'categories',
  'ja': 'categories',
  'de': 'categories',
  'fr': 'categories',
  'es': 'categorias',
  'ar': 'categories'
};

export const UserRoute = {
  'pt-br': 'especialista',
  'en': 'expert',
  'it': 'expert',
  'ja': 'expert',
  'de': 'expert',
  'fr': 'specialiste',
  'es': 'especialista',
  'ar': 'expert'
};

export const normalizeCategory = {
  'pt-br': {
    'education': 'educacao',
    'personal_development': 'desenvolvimento-pessoal',
    'business_and_careers': 'negocios-e-carreira',
    'health_and_sports': 'saude-e-esportes',
    'gastronomy': 'gastronomia',
    'finance': 'financas-e-investimentos',
    'animals_and_plants': 'animais-e-plantas',
    'relationships': 'relacionamentos',
    'law': 'direito',
    'literature': 'literatura',
    'spirituality': 'espiritualidade',
    'fashion_and_beauty': 'moda-e-beleza',
    'environment': 'ecologia-e-meio-ambiente',
    'software': 'apps-e-software',
    'idioms': 'idiomas',
    'music_and_arts': 'musica-e-artes',
    'information_technology': 'tecnologia-da-informacao',
    'building_and_constructing': 'casa-e-construcao',
    'design': 'design',
    'general': 'geral',
    'internet': 'internet'
  },
  'en': {
    'education': 'education',
    'personal_development': 'personal-development',
    'business_and_careers': 'business-and-careers',
    'health_and_sports': 'health-and-sports',
    'gastronomy': 'gastronomy',
    'finance': 'finance-and-investments',
    'animals_and_plants': 'animals-and-plants',
    'relationships': 'relationships',
    'law': 'law',
    'literature': 'literature',
    'fashion_and_beauty': 'fashion-and-beauty',
    'spirituality': 'spirituality',
    'environment': 'environment',
    'software': 'apps-and-software',
    'idioms': 'idioms',
    'music_and_arts': 'arts-and-music',
    'information_technology': 'information-technology',
    'building_and_constructing': 'building-and-constructing',
    'design': 'design',
    'general': 'general',
    'internet': 'internet'
  },
  'es': {
    'education': 'educacion',
    'personal_development': 'desarrollo-personal',
    'business_and_careers': 'negocios-y-carrera',
    'health_and_sports': 'salud-y-deportes',
    'gastronomy': 'culinaria-y-gastronomía',
    'finance': 'finanzas-e-inversiones',
    'animals_and_plants': 'animales-y-plantas',
    'relationships': 'relaciones',
    'law': 'derecho',
    'spirituality': 'espiritualidad',
    'fashion_and_beauty': 'moda-y-belleza',
    'literature': 'literatura',
    'environment': 'ecologia-y-medio-ambiente',
    'software': 'apps-y-software',
    'idioms': 'idiomas',
    'music_and_arts': 'musica-y-artes',
    'information_technology': 'tecnologia-de-la-informacion',
    'building_and_constructing': 'casa-y-construccion',
    'design': 'diseno',
    'general': 'general',
    'internet': 'internet'
  },
  'fr': {
    'education': 'education',
    'personal_development': 'developpement-personnel',
    'business_and_careers': 'affaires-et-carriere',
    'health_and_sports': 'sante-et-sports',
    'gastronomy': 'cuisine-et-gastronomie',
    'finance': 'finances-et-investissements',
    'animals_and_plants': 'animaux-et-plantes',
    'relationships': 'relations',
    'law': 'droit',
    'fashion_and_beauty': 'mode-et-beaute',
    'literature': 'litterature',
    'spirituality': 'spiritualite',
    'environment': 'ecologie-et-environnement',
    'software': 'appli-et-logiciels',
    'idioms': 'langues',
    'music_and_arts': 'arts-et-musique',
    'information_technology': 'technologies-de-l-information',
    'building_and_constructing': 'maison-et-construction',
    'design': 'design',
    'general': 'general',
    'internet': 'internet'
  },
  'ar': {
    'education': 'education',
    'personal_development': 'personal-development',
    'business_and_careers': 'business-and-careers',
    'health_and_sports': 'health-and-sports',
    'gastronomy': 'gastronomy',
    'finance': 'finance',
    'animals_and_plants': 'animals-and-plants',
    'relationships': 'relationships',
    'law': 'law',
    'literature': 'literatura',
    'fashion_and_beauty': 'fashion-and-beauty',
    'spirituality': 'spirituality',
    'environment': 'environment',
    'software': 'software',
    'idioms': 'idioms',
    'music_and_arts': 'arts-and-music',
    'information_technology': 'information-technology',
    'building_and_constructing': 'building-and-constructing',
    'design': 'design',
    'general': 'general',
    'internet': 'internet'
  },
  ja: {
    'education': 'education',
    'personal_development': 'personal-development',
    'business_and_careers': 'business-and-careers',
    'health_and_sports': 'health-and-sports',
    'gastronomy': 'gastronomy',
    'finance': 'finance',
    'animals_and_plants': 'animals-and-plants',
    'relationships': 'relationships',
    'law': 'law',
    'literature': 'literatura',
    'fashion_and_beauty': 'fashion-and-beauty',
    'spirituality': 'spirituality',
    'environment': 'environment',
    'software': 'software',
    'idioms': 'idioms',
    'music_and_arts': 'arts-and-music',
    'information_technology': 'information-technology',
    'building_and_constructing': 'building-and-constructing',
    'design': 'design',
    'general': 'general',
    'internet': 'internet'
  },
  de: {
    'education': 'education',
    'personal_development': 'personal-development',
    'business_and_careers': 'business-and-careers',
    'health_and_sports': 'health-and-sports',
    'gastronomy': 'gastronomy',
    'finance': 'finance',
    'animals_and_plants': 'animals-and-plants',
    'relationships': 'relationships',
    'law': 'law',
    'literature': 'literatura',
    'fashion_and_beauty': 'fashion-and-beauty',
    'spirituality': 'spirituality',
    'environment': 'environment',
    'software': 'software',
    'idioms': 'idioms',
    'music_and_arts': 'arts-and-music',
    'information_technology': 'information-technology',
    'building_and_constructing': 'building-and-constructing',
    'design': 'design',
    'general': 'general',
    'internet': 'internet'
  },
  it: {
    'education': 'education',
    'personal_development': 'personal-development',
    'business_and_careers': 'business-and-careers',
    'health_and_sports': 'health-and-sports',
    'gastronomy': 'gastronomy',
    'finance': 'finance',
    'animals_and_plants': 'animals-and-plants',
    'relationships': 'relationships',
    'law': 'law',
    'literature': 'literatura',
    'fashion_and_beauty': 'fashion-and-beauty',
    'spirituality': 'spirituality',
    'environment': 'environment',
    'software': 'software',
    'idioms': 'idioms',
    'music_and_arts': 'arts-and-music',
    'information_technology': 'information-technology',
    'building_and_constructing': 'building-and-constructing',
    'design': 'design',
    'general': 'general',
    'internet': 'internet'
  }
};

export enum Breakpoints {
  MOBILE = 768,
  TABLET = 820,
  DESKTOP = 1024,
  DESKTOP_WIDE = 1280
}

export enum VisibleSubsCount {
  MOBILE = 0,
  TABLET = 3,
  DESKTOP = 5,
  DESKTOP_WIDE = 7
}
